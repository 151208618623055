import { HeaderOptionsType } from 'common/globalTypes/types/common';
import isServerSide from 'utils/helpers/isServerSide';
import langConvertByCountryCode from 'utils/helpers/langConvertByCountryCode';
import getLanguageByUrl from 'utils/helpers/getLanguageByUrl';
import { HeadersEnum } from 'common/globalTypes/enums/http';
import { BEARER, cookieName } from 'common/constants/authorization';
import CookieHandler from './cookieHandler';
import { buildHeaderForTheServerSideCalls } from 'app/actions';
import { queryParamRedirectedFromDashboard } from 'common/constants/common';

const buildHeaders = async (
  options: HeaderOptionsType
): Promise<HeadersInit> => {
  if (isServerSide) {
    //detect language by url and set into header
    const detectedLang = getLanguageByUrl();
    options[HeadersEnum.xCurrentLocale] =
      langConvertByCountryCode(detectedLang);
    const authorizationToken = CookieHandler.getCookie(cookieName);
    const projectId = CookieHandler.getCookie(
      queryParamRedirectedFromDashboard
    );

    if (authorizationToken) {
      options[HeadersEnum.authorization] = `${BEARER} ${CookieHandler.getCookie(
        cookieName
      )}`;
    }

    if (projectId) {
      options[HeadersEnum.xProjectId] = projectId;
    }
  } else {
    const addHeadersForTheServerSide = await buildHeaderForTheServerSideCalls();

    if (addHeadersForTheServerSide.authorizationToken) {
      options[
        HeadersEnum.authorization
      ] = `${BEARER} ${addHeadersForTheServerSide.authorizationToken}`;
    }

    if (addHeadersForTheServerSide.projectId) {
      options[HeadersEnum.xProjectId] = addHeadersForTheServerSide.projectId;
    }
  }

  return options;
};

export default buildHeaders;
